import type { ReactNode } from 'react';
import { retryImport } from '../safe-lazy-load';

const routes = [
	{
		index: true,
		lazy: retryImport(() => import('../routes/index'))
	},
	{
		path: 'pdf-export',
		lazy: retryImport(() => import('../routes/pdf-export'))
	},
	{
		path: 'login',
		lazy: retryImport(() => import('../routes/login'))
	},
	{
		path: '*',
		lazy: retryImport(() => import('../routes/404-not-found'))
	}
];
if (import.meta.env.DEV && routes.every((r) => r.path !== 'url-debug')) {
	routes.push({
		path: 'url-debug',
		lazy: () => import('../routes/url-debug')
	});
}

// take the error element as a parameter since according to react-refresh, we can only export components from a .tsx file
export const createRoutes = (errorElement: ReactNode) => {
	return [
		{
			path: '/:language',
			lazy: retryImport(() => import('../routes/$language')),
			children: routes,
			// in development, we want the default react router error boundary to show
			errorElement: import.meta.env.DEV ? undefined : errorElement,
			bubbleError: true
		},
		{
			path: '/',
			lazy: retryImport(() => import('../routes/$language')),
			children: routes,
			errorElement: import.meta.env.DEV ? undefined : errorElement,
			bubbleError: true
		}
	];
};
