import type { QueryKey } from '@tanstack/react-query';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { api } from './api';
import { Milliseconds } from '@agritechnovation/utils';
import { AuthenticationError } from '@agritechnovation/api';
import { create } from 'zustand';

export const useGlobalErrorStore = create<{
	errors: Array<{ key: QueryKey; reset: () => void }>;
	set: (key: QueryKey, reset: () => void) => void;
	delete: (key: QueryKey) => void;
	clear: () => void;
}>((set) => ({
	errors: [],
	set: (key, reset) => {
		set((state) => {
			return {
				errors: [...state.errors, { key, reset }]
			};
		});
	},
	delete: (key) => {
		return set((state) => {
			return {
				errors: state.errors.filter((e) => e.key === key)
			};
		});
	},
	clear: () => {
		return set(() => {
			return {
				errors: []
			};
		});
	}
}));

setInterval(() => {
	useGlobalErrorStore.getState().clear();
}, Milliseconds.minutes(10));

export const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onSuccess: (_, query) => {
			useGlobalErrorStore.getState().delete(query.queryKey);
		},
		onError: (error, query) => {
			if (
				!(
					query.queryKey.includes('style') ||
					query.queryKey.includes('user') ||
					query.queryKey.includes('histogram')
				)
			) {
				useGlobalErrorStore.getState().set(query.queryKey, query.reset);
			}
			console.log('error in query', error);
			if (api.isLoggedOutError(error)) {
				console.log('logged out error');
				// errors that are from the auth module will cause an infinite loop if we try invalidate the ['user'] query
				if (error instanceof AuthenticationError) return;
				getQueryClient().refetchQueries({
					queryKey: ['user']
				});
				if (import.meta.env.DEV) {
					console.warn(
						'Unauthenticated request, please make sure query is only enabled when authenticated',
						error
					);
				}
			}
			if (import.meta.env.DEV) {
				console.error('Error in query', error);
			}
		}
	}),
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			// slightly more performant than using JSON.stringify with sorted keys (done internally by @tanstack/query-core)
			// this is to try speed up the subscriptions created by `useIsFetching` and friends which are currently incurring a high overhead
			// the downside is that we have had to make sure all query keys are arrays of simple objects (boolean, number, string)
			// possible other solutions, is to manage loading state of certain data in a global store
			queryKeyHashFn: (key) => key.join(','),
			// cache for 2 days
			gcTime: Milliseconds.days(2),
			// stale after 1 hour
			staleTime: Milliseconds.hours(1),
			networkMode: 'offlineFirst'
		}
	}
});

function getQueryClient() {
	return queryClient;
}
