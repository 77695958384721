import { Api, createTokenStorage } from '@agritechnovation/api';
import { Cookie } from '@agritechnovation/utils';
import i18n from '@agritechnovation/i18n';

function getDomain() {
	if (import.meta.env.USE_STAGING_API) return 'https://dev.myfarmweb.com';
	const mappedDomain = {
		localhost: 'https://myfarmweb.com',
		'127.0.0.1': 'https://myfarmweb.com'
	}[window.location.hostname];
	if (
		window.location.origin === 'https://dev.myfarmweb.com' &&
		window.location.pathname.startsWith('/app/preview/')
	) {
		return 'https://myfarmweb.com';
	}
	return (mappedDomain ?? window.location.origin.startsWith('http://192'))
		? 'https://myfarmweb.com'
		: window.location.origin;
}

const tokenStorage = createTokenStorage({
	getToken: async (key) => {
		return Cookie.get(key) ?? null;
	},
	setToken: async (key, token) => {
		if (token) {
			Cookie.set(key, token, {
				path: '/',
				days: 7
			});
		} else {
			Cookie.delete(key);
		}
	}
});

export const api = new Api(
	getDomain(),
	i18n.language ?? 'en',
	tokenStorage,
	'desktop'
);
