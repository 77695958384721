import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import 'tailwind/setup.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'ol/ol.css';
import { api } from './api';
import { StrictMode, useEffect } from 'react';
import '@agritechnovation/i18n';
import { ApiQueryProvider } from '@agritechnovation/api-query/dist/src/api-query.provider';
import * as Sentry from '@sentry/react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType
} from 'react-router';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoutes } from './router/createRoutes';
import { queryClient } from './query-client';
import DefaultErrorElement from './router/router.default-error-element';

if (import.meta.env.PROD) {
	Sentry.init({
		dsn: 'https://c6311f6154b9dec30632cabfe3ad7030@o433653.ingest.sentry.io/4506082711699456',
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			}),
			Sentry.replayIntegration({
				maskAllText: false
			})
		],
		// Performance Monitoring
		tracesSampleRate: 0.2,
		// Session Replay
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 0.5,
		// @ts-expect-error -- APP_VERSION is injected by vite
		release: APP_VERSION,
		environment: window.location.host
	});
}
const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
	createRoutes(<DefaultErrorElement />),
	{
		basename: import.meta.env.BASE_URL
	}
);

const prepare = async (): Promise<void> => {
	if (import.meta.env.DEV && import.meta.env.MSW_ENABLED) {
		const { worker } = await import('./msw-worker');
		await worker.start();
	}
};

const root = createRoot(document.getElementById('root') as HTMLElement);

if (process.env.CI || import.meta.env.DEV) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- needed for tests
	(window as any).queryClient = queryClient;
}
prepare().then(() => {
	root.render(
		<StrictMode>
			<QueryClientProvider client={queryClient}>
				<ApiQueryProvider value={api}>
					<RouterProvider router={router} />
				</ApiQueryProvider>
				<ReactQueryDevtools />
			</QueryClientProvider>
		</StrictMode>
	);
});
