import { useRouteError } from 'react-router';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

export default function DefaultErrorElement() {
	const error = useRouteError();
	useEffect(() => {
		if (import.meta.env.PROD) {
			Sentry.captureException(error);
		} else {
			console.error(error);
		}
	}, [error]);

	return (
		<div className="flex flex-col items-center justify-center h-full w-full pt-10">
			<div className="text-4xl font-bold">Something went wrong</div>
			<div className="text-xl">
				An Unexpected Error Occurred and Our Team has been notified
			</div>
			<a
				href={window.location.pathname}
				className="py-1 px-2 bg-primary text-white rounded mt-4"
			>
				Start Over
			</a>
		</div>
	);
}
