import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import type { LayerFilter } from '@agritechnovation/api';

export const initI18n = () =>
	i18n.use(initReactI18next).init({
		fallbackLng: 'en',
		defaultNS: 'COMMON',
		fallbackNS: 'COMMON',
		debug: import.meta.env.DEV,
		resources: {
			en: {
				COMMON: {
					en: 'English',
					af: 'Afrikaans',
					es: 'Spanish',
					all: 'All',
					speed: 'Speed',
					retry: 'Retry',
					details: 'Details',
					uploadedBy: 'Uploaded By',
					myInfrastructure: 'My Infrastructure',
					upload: 'Upload',
					save: 'Save',
					uploading: 'Uploading...',
					cancel: 'Cancel',
					description: 'Description',
					errorOccurred: 'An error occurred',
					noDataAvailable: 'No data available',
					noDataFound: 'No data found',
					cannotLoadFarmInfo: 'Cannot load farm info',
					cannotLoadFarms: 'Cannot load farms',
					cannotLoadBoundary: 'Cannot load boundary',
					cannotLoadBlocks: 'Cannot load blocks',
					cannotLoadClients: 'Cannot load clients',
					failedToGetOneOrMoreBoundaries:
						'Failed to get one or more boundaries',
					pleaseSelectAFilter: 'Please select a filter',
					pleaseSelectAFarm: 'Please select a farm',
					features: 'Features',
					selectFarmsBy: 'Select Farms By',
					farm: 'Farm',
					farms: 'Farms',
					block: 'Block',
					blocks: 'Blocks',
					boundary: 'Boundary',
					client: 'Client',
					clients: 'Clients',
					entity: 'Entity',
					entities: 'Entities',
					apply: 'Apply',
					reset: 'Reset',
					dataProvidedBy: 'Data provided by',
					settings: 'Settings',
					dateSelect: 'Date Select',
					opacity: 'Opacity',
					layer: 'Layer',
					harvestDate: 'Harvest Date',
					left: 'Left',
					right: 'Right',
					totalArea: 'Total Area'
				}
			},
			af: {
				COMMON: {
					en: 'English',
					af: 'Afrikaans',
					es: 'Spanish',
					all: 'Alles',
					speed: 'Spoed',
					retry: 'Probeer weer',
					details: 'Besonderhede',
					uploadedBy: 'Gelaai deur',
					myInfrastructure: 'My Infrastruktuur',
					upload: 'Laai op',
					save: 'Stoor',
					uploading: 'Laai op...',
					cancel: 'kanselleer',
					description: 'Beskrywing',
					errorOccurred: "Daar was 'n fout",
					noDataAvailable: 'Geen data beskikbaar',
					noDataFound: 'Geen data gevind nie',
					cannotLoadFarmInfo: 'Kan nie plaas inligting laai nie',
					cannotLoadFarms: 'Kan nie plase laai nie',
					cannotLoadBoundary: 'Kan nie grens laai nie',
					cannotLoadBlocks: 'Kan nie blokke laai nie',
					cannotLoadClients: 'Kan nie kliënte laai nie',
					failedToGetOneOrMoreBoundaries: 'Kon nie een of meer grense kry nie',
					pleaseSelectAFilter: 'Kies asseblief ’n filter',
					pleaseSelectAFarm: 'Kies asseblief ’n plaas',
					features: 'Eienskappe',
					selectFarmsBy: 'Kies plase deur',
					farm: 'Plaas',
					farms: 'Plase',
					block: 'Blok',
					blocks: 'Blokke',
					boundary: 'Grens',
					client: 'Kliënt',
					clients: 'Kliënte',
					entity: 'Entiteit',
					entities: 'Entiteite',
					apply: 'Aansoek doen',
					reset: 'Herstel',
					dataProvidedBy: 'Data verskaf deur',
					settings: 'Instellings',
					dateSelect: 'Datum Kies',
					opacity: 'Oordekking',
					layer: 'Laag',
					harvestDate: 'Oesdatum',
					left: 'Links',
					right: 'Regs',
					totalArea: 'Totale Oppervlakte'
				}
			},
			es: {
				COMMON: {
					en: 'English',
					af: 'Afrikaans',
					es: 'Español',
					all: 'Todos',
					speed: 'Velocidad',
					retry: 'Reintentar',
					details: 'Detalles',
					uploadedBy: 'Subido por',
					myInfrastructure: 'Mi Infraestructura',
					upload: 'Subir',
					save: 'Guardar',
					uploading: 'Subiendo...',
					cancel: 'Cancelar',
					description: 'Descripción',
					errorOccurred: 'Ocurrió un error',
					noDataAvailable: 'No hay datos disponibles',
					noDataFound: 'No se encontraron datos',
					cannotLoadFarmInfo: 'No se puede cargar la información de la finca',
					cannotLoadFarms: 'No se pueden cargar las fincas',
					cannotLoadBoundary: 'No se puede cargar el límite',
					cannotLoadBlocks: 'No se pueden cargar los bloques',
					cannotLoadClients: 'No se pueden cargar los clientes',
					failedToGetOneOrMoreBoundaries:
						'No se pudo obtener uno o más límites',
					pleaseSelectAFilter: 'Por favor seleccione un filtro',
					pleaseSelectAFarm: 'Por favor seleccione una finca',
					features: 'Características',
					selectFarmsBy: 'Seleccionar fincas por',
					farm: 'Finca',
					farms: 'Fincas',
					block: 'Bloque',
					blocks: 'Bloques',
					boundary: 'Límite',
					client: 'Cliente',
					clients: 'Clientes',
					entity: 'Entidad',
					entities: 'Entidades',
					apply: 'Aplicar',
					reset: 'Reiniciar',
					dataProvidedBy: 'Datos proporcionados por',
					settings: 'Configuración',
					dateSelect: 'Fecha Seleccionada',
					opacity: 'Opacidad',
					layer: 'Capa',
					harvestDate: 'Fecha de Cosecha',
					left: 'Izquierda',
					right: 'Derecha',
					totalArea: 'Área Total'
				}
			}
		},
		interpolation: {
			escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		}
	});

initI18n().then(() => {
	if (import.meta.env.DEV) {
		console.log('i18n initialized');
	}
});
export const waitForInit = () => {
	return new Promise((resolve) => {
		if (i18n.isInitialized) {
			resolve(true);
			return;
		}
		i18n.on('initialized', () => {
			resolve(true);
		});
	});
};

export type Resources = {
	en: {
		[key: string]: string;
	};
	af: {
		[key: string]: string;
	};
	es: {
		[key: string]: string;
	};
};

export const useAddNamespaceResources = (
	namespace: string,
	resources: Resources
) => {
	const { i18n } = useTranslation();
	const addNamespace = useCallback(
		(namespace: string, resources: Resources) => {
			for (const [key, resource] of Object.entries(resources)) {
				const bundle = i18n.getResourceBundle(key, namespace);
				if (!bundle) {
					i18n.addResourceBundle(key, namespace, resource);
				}
			}
		},
		[i18n]
	);

	const [loaded, setLoaded] = useState(false);

	const onAdded = useCallback(
		(lang: string, ns: string) => {
			if (ns === namespace && lang === i18n.language) {
				setLoaded(true);
			}
		},
		[i18n.language, namespace]
	);

	useEffect(() => {
		i18n.store.on('added', onAdded);
		return () => {
			i18n.store.off('added', onAdded);
		};
	}, [i18n, onAdded]);

	useEffect(() => {
		addNamespace(namespace, resources);
	}, [addNamespace, namespace, resources]);

	return loaded || i18n.hasResourceBundle(i18n.language, namespace);
};

export type SupportedLanguages = 'en' | 'af' | 'es';

const PDF_POINT_LAYERS = ['images', 'reports'];

export function getTranslatedLayerFilterName(layerFilter?: LayerFilter) {
	if (!layerFilter) {
		return '';
	}
	const translation =
		layerFilter[`name_${i18n.language as SupportedLanguages}`];

	if (PDF_POINT_LAYERS.includes(layerFilter.layer_name)) {
		return `${layerFilter.filter_group}:${translation}`;
	}

	return translation ?? layerFilter.name;
}

export { useTranslation } from 'react-i18next';
export { SMART_LAYER_KEY_TRANSLATIONS } from './products/smart-layer.key-translations';
export default i18n;
